
@import url("https://fonts.googleapis.com/css2?family=Marhey:wght@400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
}

img {
  width: 100%;
 
  user-select: none;
}

ul {
  list-style-type: none;
}

h1 {
  font-family: "Marhey", sans-serif;
  font-size: clamp(1.9rem, 2vw, 3rem);
  opacity: 0;
  animation: title 1.1s ease 0.3s 1 normal forwards;
}

@keyframes title {
  0% {
    opacity: 0;
    letter-spacing: 10px;
    color: #ebeeff;
  }

  100% {
    opacity: 1;
    letter-spacing: 0;
    color: #fff;
  }
}

.hidden {
  display: none;
}

html,
body {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url(./assets/images/app_back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* ------------ */
/* ICON STYLES */

.icon-container {
  position: absolute;
  z-index: 40;
  bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 10px 14px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 12px;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 12px;
  color: #fff;
  font-size: 1.5rem;
  padding: 5px;
  cursor: pointer;
  transition: all 0.4s;
}

.icon-box:hover {
  transform: scale(1.1);
}

.about {
  background: rgb(182, 164, 248);
  background: linear-gradient(
    30deg,
    rgb(182, 164, 248) 0%,
    rgb(75, 52, 159) 70%
  );
}

.testimonial {
  background-color: #f6c66e;
  background-image: linear-gradient(30deg, #f6c66e 0%, rgb(168, 78, 22) 100%);
}

.projects {
  background: rgb(169, 207, 124);
  background: linear-gradient(
    310deg,
    rgb(169, 207, 124) 0%,
    rgb(77, 120, 31) 60%
  );
}

.contact {
  background-color: rgb(224, 129, 157);
  background-image: linear-gradient(
    340deg,
    rgb(243, 151, 178) 0%,
    rgb(147, 20, 58) 100%
  );
}

/* ------- */
/* POPUP */

body.prevent-background-scroll {
  min-height: 100dvh;
  overflow-y: hidden;
}

.prevent-scroll {
  overflow: hidden;
}

#about {
  display: block;
}

.popup {
 
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
  animation: openPopup 0.6s ease-in-out 1 normal;
}

@keyframes openPopup {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.popup-container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: min(900px, 90%);
  background: rgba(133, 133, 133, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  color: #fff;
  z-index: 30;
  transition: all 0.5s ease-in-out;
}

.popup-header {
  position: relative;
  display: flex;
  gap: 8px;
  font-weight: 600;
  padding: 15px 20px 10px;
  font-size: 1.2rem;
}

.popup-body {
  color: #f7f7f7;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px 50px;
}

.popup.maximized .popup-body {
  height: 100vh;
}

.button-container {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.circle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 0;
  outline: 0;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.2s;
}

.circle-btn svg {
  opacity: 0;
  transition: all 0.2s;
  font-size: 0.5rem;
  color: rgb(65, 66, 67);
}

.circle-btn:hover {
  opacity: 1;
  transform: scale(1.03);
}

.button-container:hover svg {
  opacity: 1;
}

/* .button-container:hover i:hover,
.button-container:hover i:hover:nth-child(2),
.button-container:hover i:hover:nth-child(3) {
  opacity: 1;
} */

.red {
  background-color: rgb(255, 96, 92);
}

.red svg {
  font-size: 0.8rem;
}

.yellow {
  background-color: rgb(255, 189, 68);
}

.yellow svg {
  transform: translateY(-3px);
}

.green {
  background-color: rgb(0, 202, 78);
}

.green svg {
  transform: rotate(90deg);
}

/* --------- */
/* SCROLLBAR */

.popup-body::-webkit-scrollbar {
  width: 0.7rem;
}

.popup-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.375rem rgb(79, 78, 78);
  border-radius: 0.8rem;
}

.popup-body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0.375rem rgba(238, 238, 238, 0.9);
  outline: none;
  border-radius: 0.8rem;
}

/* ------ */
/* ABOUT */

.about-container {
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 30px;
  place-items: center;
}

.about-container h1 {
  margin-bottom: 20px;
  line-height: 1.4;
}

.about-container p {
  line-height: 1.6;
  font-size: 1.1rem;
}

.about-container .img-frame {
  overflow: hidden;
  width: 250px;
  border-radius: 8px;
  box-shadow: rgba(62, 63, 168, 0.8) 0px 10px 30px 8px,
    rgba(62, 63, 168, 0.8) 0px 0px 0px 2px;
}

.about-container .img-frame img {
  aspect-ratio: 6/7;
  object-fit: cover;
  transition: transform 1s;
}

.about-container .img-frame:hover img {
  transform: rotate(4deg) scale(1.25);
}

/* ------- */
/* SKILLS */

.skill-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.skill-list h1 {
  text-align: center;
  margin-bottom: 30px;
}

.skill-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: min(100%, 400px);
  margin: 0 auto;
}

.skill-list ul li {
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background: linear-gradient(
    130deg,
    rgba(162, 234, 55, 0.7) 0%,
    rgba(46, 152, 74, 0.5) 100%
  );
  backdrop-filter: blur(30px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 8px 12px;
}

/* --------- */
/* PROJECTS */

.project-container h1 {
  text-align: center;
  animation-delay: 0.8s;
  margin: 20px 0;
}

.all-projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  place-items: center;
  gap: 30px;
  padding: 30px;
}

.all-projects img {
  height: 200px;
  object-fit: fill;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.project-box {
  position: relative;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(10, 98, 13, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #000;
  border-radius: 12px;
  list-style: none;
  z-index: 1;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.overlay:hover {
  opacity: 1;
  backdrop-filter: blur(2px);
}

.overlay h3,
.overlay button {
  opacity: 0;
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
}

.overlay:hover h3,
.overlay:hover button {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}

.overlay:hover h3 {
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
}

.overlay:hover button {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
}

.more-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #fff;
  background-color: transparent;
  margin-top: 20px;
  cursor: pointer;
}

.more-btn::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #fff;
  z-index: -1;
  transition: all 0.4s;
}

.more-btn:hover::before {
  height: 100%;
  top: 0;
}

.more-btn span {
  color: #fff;
  line-height: 1;
  transition: color 0.4s;
}

.more-btn:hover span {
  color: #000;
}

/* ------------- */
/* TESTIMONIALS */

.testimonial-container h1 {
  text-align: center;
  margin-bottom: 40px;
  user-select: none;
}

.slider-container {
  width: 100%;
  position: absolute;
  top: 60%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.swiper {
  width: 94%;
  height: 100%;
  padding: 5px 0;
}

.swiper-slide {
  width: 260px;
  height: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  background: rgba(104, 104, 104, 0.5);
  color: #fff;
  outline: 0 solid rgba(225, 193, 88, 0.8);
  border-radius: 15px;
  padding: 20px 30px;
  margin-bottom: 16px;
  cursor: grab;
  user-select: none;
  transition: outline 0.3s linear;
}

.swiper-slide:hover {
  outline: 1px solid rgba(225, 193, 88, 0.8);
}

.user-info {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.user-info img {
  max-width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  box-shadow: rgba(225, 193, 88, 0.8) 0px 10px 30px 0px,
    rgba(225, 193, 88, 0.8) 0px 0px 0px 2px;
}

.user-info h2 {
  font-size: 1.1rem;
  transform: translateY(-5px);
}

.swiper-slide p {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 12px;
}

/* Control Buttons */

.control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 6px;
  margin-top: 16px;
}

.control li {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(237, 223, 36, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4),
    inset 0px 4px 4px rgba(255, 255, 255, 0.2);
  padding: 10px;
  margin: 3px;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.4s ease-in;
}

.control li:hover {
  background: rgba(224, 198, 26, 0.8);
  transform: scale(0.98);
}

.arrow {
  font-size: 1.8rem;
  color: #fff;
}

/* -------- */
/* CONTACT */

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 0;
}

.contact-container h1,
.contact-container p {
  text-align: center;
  margin-bottom: 16px;
}

.contact-container p {
  font-size: 1rem;
  margin-bottom: 30px;
}

form {
  width: min(650px, 95%);
}

input[type="text"],
select,
textarea {
  font-size: 1rem;
  width: 100%;
  padding: 14px;
  background-color: transparent;
  color: #fff;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0 20px;
  resize: vertical;
}

input[type="text"]:focus-visible,
select:focus-visible,
textarea:focus-visible {
  border: 2px solid rgb(220, 20, 80);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
}

.submit-btn {
  padding: 12px 20px;
  font-size: 1.1rem;
  margin: 10px auto 0;
}

.submit-btn span {
  display: flex;
  gap: 10px;
}

@media (max-width: 900px) {
  .popup-body {
    padding: 30px;
  }
}

@media (max-width: 750px) {
  .about-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 4fr;
    grid-template-areas:
      "img-frame"
      "hero_content";
    padding: 160px 30px 50px;
  }

  .about-container .img-frame {
    grid-area: img-frame;
    width: 230px;
    aspect-ratio: 1/1;
  }

  .hero_content {
    grid-area: hero_content;
    margin-top: 110px;
    text-align: center;
  }

  .about-container h1 {
    margin-bottom: 16px;
  }
}

@media (max-width: 600px) {
 

  .user-info h2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .about-container .img-frame {
    grid-area: img-frame;
    width: 180px;
    aspect-ratio: 1/1;
  }

  .all-projects img {
    height: 180px;
  }
}